import React from "react";
import { createRoot } from "react-dom/client";

import "./global.scss";
import "@fontsource/cormorant-garamond";
import "@fontsource/poppins";
import "@fortawesome/fontawesome-pro/css/all.css";
import "@fortawesome/fontawesome-pro/css/brands.min.css";
import "./Main.scss";
import Socials from "./Socials";
import Menu from "./Menu";
import TitleSection from "./Sections/TitleSection";

import pic1 from "../assets/DSC_6543_WEB.jpg";
import pic2 from "../assets/DSC_6646_WEB.jpg";
import pic3 from "../assets/DSC_6651_WEB.jpg";
import pic4 from "../assets/DSC_6890_WEB.jpg";
import pic5 from "../assets/DSC_6915_WEB.jpg";
import pic6 from "../assets/DSC_6823_WEB.jpg";

export default function Main() {
  return (
    <>
      <Socials />
      {/* <Menu /> */}
      {/* <TitleSection /> */}

      <section className="BigSection TitleSection"></section>

      <section className="BigSection">
        <div></div>
        <div className="AboutUsBlock">
          <div className="Title title-font">About Us</div>
          <p>
            <strong>
              Ivy Creations fits into a niche hair and make-up market for
              Queenscliff, Bellarine and surrounding areas.
            </strong>
          </p>
          <p>
            Nestled amongst the boats of Queenscliff's harbour, we have brought
            a touch of luxury to the tiny seaside town.
          </p>
          <p>
            Our vision and focus is to eliminate our footprint as much as
            possible on our planet, whilst also not compromising on our clients'
            goals.
          </p>
          <p>
            All our products are <strong>cruelty free</strong>, naturally and
            ethically sourced, <strong>free from chemicals</strong>, and will be
            specifically tailored to you, to give you the very best result.
          </p>
          <p>
            Our team are highly trained and nationally well-known for our
            seamless blends of colour, with the health of your hair always the
            most important factor.
          </p>
          <p>
            Our precision cutting and hair styling ranges from glamour blow outs
            to tassels, curls, and sleek straight finishes.
          </p>
        </div>
        <div></div>
      </section>

      <section className="BigSection" style={{ backgroundColor: "#fcfcfc" }}>
        <div></div>
        <div>
          <div className="Title title-font">Gallery</div>

          <div className="PictureGrid">
            <div>
              <img src={pic1} />
            </div>
            <div>
              <img src={pic2} />
            </div>
            <div>
              <img src={pic3} />
            </div>
            <div>
              <img src={pic4} />
            </div>
            <div>
              <img src={pic5} />
            </div>
            <div>
              <img src={pic6} />
            </div>
          </div>
        </div>
        <div></div>
      </section>

      {/* 
      <div className="BigSection flex column fill">
        <div className="flex row fill">
          <div className="SalonPicture flex fill"></div>
          <div className="AboutUs title-font flex column fill center-x center-y">
          </div>
        </div>
      </div>

      <div className="BigSection flex column fill center-y">
        <section>
          <div className="StyleSection flex column fill center-x center-y">
            <div className="TitleSection Title title-font">
              Ivy<em className="primary-colour">services</em>
            </div>

            <div className="Subtext flex column fill center-x">
              <p className="text-muted">
                We specialise in bridal and bridal party hair and make-up for
                your special day.
              </p>
              <p className="text-muted">
                We can even come to you. *Call out fee applies. *Prices are
                subject to change / or at consultation
              </p>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
}

const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);
