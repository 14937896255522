import React from "react";

import "./Socials.scss";
import logo from "../assets/Ivy-Creations-Logo-400px.jpg";

function Socials() {
  return (
    <section className="SocialsInner">
      <div></div>
      <div className="MainHeader">
        <div className="flex row fill">
          <img className="Logo" src={logo} height={128} alt="Ivy Creations" />
        </div>
        <div className="Socials flex row">
          <a
            href="https://www.facebook.com/ivycreationsqueenscliffe"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-thin fa-fw fa-facebook-f"></i>
          </a>

          <a
            href="https://www.instagram.com/ivycreationshair"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-thin fa-fw fa-instagram"></i>
          </a>
          {/* <a href="tel:+61352584764">(03) 5258 4764</a> */}
        </div>
      </div>
      <div></div>
    </section>
  );
}

export default Socials;
